<template>
    <div>
         <div class='home'>
            <div class='_right_content'>
                <img v-bind:src="logo" style="height:100px"/>
            </div>
            <a-spin :spinning="spinning" :delay="delayTime">
            <div style="padding: 2px;">
            <a-descriptions :title="$t('home.company_profile')">
                <a-descriptions-item :label="$t('home.brand_name')">
                {{brand_name}}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('home.contacts')">
                {{contact_name}}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('home.contacts_number')">
                {{contact_no}}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('home.company_address')">
                {{company_address}}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('home.email')">
                    {{email}}
                </a-descriptions-item>
                <a-descriptions-item :label="$t('home.website')">
                    {{website}}
                </a-descriptions-item>
            </a-descriptions>
            </div>
            </a-spin>
            
            <a-divider orientation="left">{{$t('home.products')}}</a-divider>
            <a href="javascript:void(0)" style="float: right;" @click="action_productlist()">
                {{$t('home.more')}}<a-icon type="double-right" />
            </a>
            <div style="padding: 2px;clear:both;">
                <a-row :gutter="16">
                    <a-col :span="4" v-for="(item,i) in productData" :key="item.product_id">
                        <a-card hoverable style="width: 100%;" @click="showDrawer()" v-if="i<6">
                            <img slot="cover" alt="" v-bind:src="item.main_img"/>
                            <a-card-meta v-bind:title="item.title">
                            <template slot="description"></template>
                            </a-card-meta>
                        </a-card>
                    </a-col>
                </a-row>
            </div>
            <a-divider orientation="left">{{$t('home.press_and_news')}}</a-divider>
            <div style="padding: 2px;">
            <a-list item-layout="horizontal" :data-source="pressesData">
                <a-list-item slot="renderItem" slot-scope="item" @click="showNews(item.press_id)">
                    <a-list-item-meta v-bind:description="item.keywords">
                        <a slot="title" href="javascript:void(0)">{{ item.title }}</a>
                        <a-avatar slot="avatar" v-bind:src="item.img"/>
                    </a-list-item-meta>
                    <div>{{item.post_date}}</div>
                </a-list-item>
            </a-list>
            </div>
            <a-divider orientation="left">{{$t('home.media')}}</a-divider>
            <div style="padding: 2px;">
                <a-row :gutter="16">
                    <a-col :span="4" v-for="(item,i) in mediaData" :key="item.media_id">
                        <a-card hoverable style="width: 100%;" @click="action_location(item.url)" v-if="i<6">
                            <img slot="cover" alt="" v-bind:src="item.img"/>
                            <a-card-meta v-bind:title="item.asset">
                            <template slot="description">
                                <div class="media_div">{{item.description}}</div>
                            </template>
                            </a-card-meta>
                        </a-card>
                    </a-col>
                </a-row>
            </div>
        </div>

        <a-drawer
        title="Info"
        placement="right"
        :closable="false"
        :visible="visible"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
        >
        <!-- <a-button type="danger">潜在爆款</a-button> -->
        <p>最热卖刊登：0件/月销量 </p>
        <p>总刊登：0件/月销量</p>
        <p>竞争潜力：<a-progress :percent="percent" size="small" />0个热卖刊登，总刊登0</p>
        <p>产品热度：<a-progress :percent="percent" size="small" />30天平均日销量0</p>
        <p>产品需求：<a-progress :percent="percent" size="small" />热卖刊登平均日销量0</p>
        <a-divider orientation="left"></a-divider>
        <p>产品id：</p>
        <p>站点：</p>
        <p>发货地：</p>
        <p>推荐售价：</p>
        <p>首次上架：</p>
        </a-drawer>

        <a-drawer
        title="News"
        :width="720"
        :visible="visibleNews"
        :body-style="{ paddingBottom: '80px' }"
        @close="onCloseNews"
        >
        <a-descriptions v-bind:title="News.title">
            <a-descriptions-item label="author">
            {{News.author}}
            </a-descriptions-item>
            <a-descriptions-item label="date">
            {{News.post_date}}
            </a-descriptions-item>
        </a-descriptions>
         <a-divider dashed />
        <div v-html="News.content" class='news_div' style="width:100%;">
        </div>
        
        </a-drawer>
    </div>
</template>

<script>
import { PageHeader, Divider,Steps } from 'ant-design-vue';
import {getProfile} from '@/network/common';
import {getMedias} from '@/network/mediarequest';
import {getPresses,getPressDetail} from '@/network/pressrequest';
// import {getProducts} from '@/network/prorequest';
import {getProducts} from '@/network/navigation';

const { Step } = Steps;
export default {
    name: 'NodeprojectHome',
    components: {PageHeader, Divider,Steps,Step,getProfile},
    directives: {  },
    data() {
        return {
            spinning: false,
            delayTime: 500,
            logo:'',
            contact_name:'',
            contact_no:'',
            company_address:'',
            brand_name:'',
            company_name:'',
            website:'',
            email:'',
            mediaData:[],
            pressesData:[],
            productData:[],
            visible: false,
            visibleNews: false,
            News:{
                author: "",
                content: "",
                img: "",
                keywords: "",
                post_date: "",
                press_id: "",
                status: "",
                title:'',
            },
            percent:0
        };
    },
    created() {
        this.spinning = true;
        getProfile()
        .then(res=>{
          console.log(res);
          if(res.header.code==200){
            this.logo = res.body.profile.logo;
            this.contact_name = res.body.profile.contact_name;
            this.contact_no = res.body.profile.contact_no;
            this.company_address = res.body.profile.company_address;
            this.brand_name = res.body.profile.brand_name;
            this.company_name = res.body.profile.company_name;
            this.website = res.body.profile.website;
            this.email = res.body.profile.email;
          }
            this.spinning = false;
        });
        getMedias(1)
        .then(res=>{
            // console.log(res);
            if(res.header.code==200){
                // this.total_rows=res.body.total_rows;
                this.mediaData=res.body.medias;
            }
        });
        getPresses(1)
        .then(res=>{
            if(res.header.code==200){
                // this.total_rows=res.body.total_rows;
                this.pressesData=res.body.presses;
            }
        });
        getProducts(1)
        .then(res=>{
            if(res.header.code==200){
                // this.total_rows=res.body.total_rows;
                this.productData = res.body.products;
            }
        });
    },
    mounted() {
         
    },
    methods: {
        action_productlist(){
            this.$router.push({path:'/home/productlist'})
        },
        showNews(press_id){
            // console.log(press_id);
            getPressDetail(press_id)
            .then(res=>{
            console.log(res)
                if(res.header.code==200){
                    this.visibleNews = true;
                    this.News = res.body.press;
                }
            });
        },
        onCloseNews(){
            this.visibleNews = false;
        },
        action_location(url){
            window.open(url,'_blank')
        },
        info(obj) {
            const h = this.$createElement;
            this.$info({
                title: 'This is a notification message',
                mask:false,
                dialogStyle:"{ top: '20px' }",
                content: h('div', {}, [
                h('p', 'some messages...some messages...'),
                h('p', 'some messages...some messages...'),
                ]),
                onOk() {},
            });
        },
        afterVisibleChange(val) {
            console.log('visible', val);
        },
        showDrawer() {
            this.visible = true;
        },
        onClose() {
            this.visible = false;
        },
    },
};
</script>

<style  scoped>
.news_div img{
    max-width: 100%;
}
.ant-card-bordered {
    margin-bottom: 5px;
}
.ant-card-body {
    padding: 14px 3px;
}
.ant-card-meta-title {
    font-size: 14px;
    font-weight: 400;
}
.media_div {
    overflow: hidden;
    height: 100px;
    overflow-y: auto;
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
 
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: 999px;
  border: 5px solid transparent;
}
 
::-webkit-scrollbar-track {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
}
 
::-webkit-scrollbar-thumb {
  min-height: 20px;
  background-clip: content-box;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2) inset;
}
 
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>