import {request} from './request'
import Cookies from 'js-cookie'
import qs from 'qs' 
// const maker_id=Cookies.get("maker_id")
const maker_id=Cookies.get("maker_id")
export function getMediaTypes() {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/media/getMediaTypes.php',params:{maker_id}})
}
export function getMedias(page,media_type_id,rows_per_page=10) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/media/getMedias.php',params:{page,maker_id,media_type_id,rows_per_page}})
}
export function getMediaDetail(media_id) {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/media/getMedia.php',params:{media_id,maker_id}})
}
export function deleteMedia(media_id) {
    var maker_id = Cookies.get("maker_id");
    let data={media_id,maker_id}
    return request({url:'/media/deleteMedia.php',method:'post',data:qs.stringify(data)})
}
export function addMedia(data) {
    data.maker_id= Cookies.get("maker_id");
    return request({url:'/media/addMedia.php',method:'post',data:qs.stringify(data)})
}
export function editMedia(data) {
    data.maker_id= Cookies.get("maker_id");
    return request({url:'/media/editMedia.php',method:'post',data:qs.stringify(data)})
}

